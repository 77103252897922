import * as React from 'react';

const HandSVG = () => {
  return (
    <svg width="42pt" height="42pt" viewBox="0 0 64 64">
      <path
        d="M19.555 39.781c-.551.516-1.106-.101-1.106-.101s-9.238-9.79-9.84-10.23c-.843-.614-2.656-1.579-4.464.284-.754.778-1.985 2.5.3 5.282.496.597 14.89 15.77 15.73 16.59 0 0 6.65 6.468 10.677 8.906 1.113.672 2.367 1.386 3.832 1.886 1.46.5 3.113.848 4.851.848 1.738.02 3.543-.316 5.25-.941 1.703-.63 3.293-1.543 4.738-2.602.356-.27.715-.539 1.051-.828l.973-.8a28.391 28.391 0 001.906-1.766 31.505 31.505 0 003.277-3.829 26.512 26.512 0 002.426-4.074 18.379 18.379 0 001.38-4.047c.288-1.293.37-2.52.323-3.59-.007-1.07-.222-1.984-.398-2.714a9.849 9.849 0 00-.586-1.633c-.164-.363-.254-.55-.254-.55-.23-.645-.45-1.263-.644-1.817a447.491 447.491 0 00-2.258-6.235l.008.016a1199.75 1199.75 0 00-5.032-13.371c-.394-1.195-1.851-1.61-2.922-.84-3.09 2.219-4.035 5.46-2.945 8.914l2.797 7.66c.395.856-.695 1.848-1.426 1.25-2.293-1.87-7.148-7.023-7.148-7.023-2.172-2.082-14.414-14.637-15.235-15.403-1.652-1.535-3.73-2.324-5.316-1.16-1.621 1.192-2.07 3.032-.504 5.043.426.543 12.8 13.617 12.8 13.617.72.758-.132 1.829-.925 1.09 0 0-15.394-16.058-16.09-16.808-1.578-1.711-4.105-2.086-5.605-.676-1.465 1.375-1.43 3.633.168 5.402.511.559 11.355 12.008 15.695 16.7.29.316.516.734.086 1.128-.004.004-.441.477-1-.125-1.18-1.257-12.965-13.539-13.621-14.203C9 17.5 6.98 16.773 5.355 18.266c-1.48 1.355-1.718 3.718-.02 5.39l14.274 15.09s.465.55-.054 1.035zm0 0"
        fill="#fac036"
      />
      <path
        d="M42.73 27.2l1.204 1.288s-6.895 6.657-2.196 16.875c0 0 .614 1.297-.187 1.512 0 0-.7.39-1.5-1.602 0-.003-4.746-9.707 2.68-18.074zm0 0"
        fill="#e48c15"
      />
      <path
        d="M31.64 5.102s2.907.437 5.594 3.32c2.692 2.883 3.938 6.59 3.938 6.59M38.719 1.75S41.156 2.977 43.035 6c1.88 3.023 2.336 6.523 2.336 6.523M17.855 55.363s-2.93.196-6.175-2.043c-3.246-2.242-5.258-5.59-5.258-5.59M15.766 60.281s-2.72.114-5.84-1.61c-3.121-1.726-5.223-4.562-5.223-4.562"
        fill="none"
        strokeWidth={2}
        strokeLinecap="round"
        stroke="#444"
        strokeMiterlimit={10}
      />
    </svg>
  );
};

export default HandSVG;
